import React from "react";

const Header1 = ({ children, className }) => (
    <h2
        className={`${className} text-outragous-orange text-4xl lg:text-5xl font-normal leading-8`}
    >
        {children}
    </h2>
);

export default Header1;
